import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Link from 'gatsby-link'
import { Title, Box, Text, Button, Span } from "../../components/Core";
import { device } from "../../utils";
import imgCase1 from "../../assets/image/jpeg/nonnose.jpg";
import imgCase2 from "../../assets/image/jpeg/bb.jpg";
import imgCase3 from "../../assets/image/jpeg/bb1.jpg";



const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #999;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #999;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;






const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: transparent;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:1;


`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 4.2em;

      margin-bottom: 0.4em;
  
      color: #000;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 36px;
          text-align:center;
      }

`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;
font-family: halyard-display, sans-serif;

width: 90%;
color:#999;



font-size: 1em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 12px;
     text-align:center;
     width: 100%;
 }

`;

const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const CaseCardStyled = styled(Box)`
  width: 320px;
  transition: 0.4s;
  margin: 0 auto;
font-size:14px;
  .img-container {
    position: relative;
    z-index: 1;
    img {
      border-radius: 8px 8px 0 0;
      max-width: 100%;
    }
  }

  &:hover {
    transform: translateY(-20px);
  }
  &:hover i {
    transform: translateX(10px);
    opacity: 1;
  }
`;

const BtnContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin-top: 20px;
  opacity: 0;
  transition: 0.4s opacity, 0.4s margin-top;
  height: 40px;
  font-size: 16px;
  text-align: center;
  ${CaseCardStyled}:hover & {
    opacity: 1;
    margin-top: 0px;
  }
  button {
    font-size: 16px;
    padding: 0.5rem 1.25rem;
    @media ${device.sm} {
      font-size: 18px;
      padding: 0.75rem 1.5rem;
    }
    @media ${device.lg} {
      padding: 0.85rem 1.75rem;
    }
  }
`;

const TextContent = styled(Box)`
  position: relative;
  z-index: 1;
  transition: 0.4s;
  font-size:14px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  @media ${device.sm} {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 38px;
    padding-right: 38px;
  }
`;

const Shape = styled(Box)`
  background: initial;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  bottom: 98%;
  @media ${device.sm} {
    bottom: 99%;
  }
  @media ${device.xl} {
    bottom: 100%;
  }
  svg {
    width: 100%;
    path {
      fill: ${({ theme, bg }) => theme.colors[bg]};
      transition: 0.4s;
    }
  }
`;

const PreTitle = styled(Text)`
  font-size: 16px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 16px;
  margin-bottom: 22px;
`;

const TitleStyled = styled(Title)`
  letter-spacing: -1.06px;
  margin-bottom: 22px;
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  @media ${device.sm} {
    font-size: 18px;
    font-weight: 500;
  }
`;

const CaseCard = ({
  isDark = true,
  bg = "#000",
  img,
  meta = "",
  title = "",
  children = "",
}) => {
  return (
    <CaseCardStyled>
      <div className="img-container">
        <img src={img} alt="Non Surgical Nose Job London" />
        <BtnContainer>
          <Button>View Blog</Button>
        </BtnContainer>
      </div>
      <TextContent bg="#000">
      
        <PreTitle color={isDark ? "lightShade" : "darkShade"}>{meta}</PreTitle>
        <TitleStyled color={isDark ? "light" : "dark"}>{title}</TitleStyled>
        <Text color={isDark ? "lightShade" : "darkShade"}>{children}</Text>
      </TextContent>
    </CaseCardStyled>
  );
};

const Blo = () => {
  return (
    <>
      {/* <!-- Content section --> */}
      <Box >
        <Container className="pb-md-5 mb pt-md-5">

        <Row className="  mt-5  mb-0">
                  <Col>
                  <Iwrap>


<Itext as="h2">Non Surgical Rhinoplasty News</Itext>
</Iwrap>
      <SecondText>
   Our Blog
      </SecondText>
                  </Col>
                </Row>
          <Row className="justify-content-center  mb-5">
            <Col
              lg="4"
              md="9"
              className="mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
            >
                <Link to="/blog/non-surgical-nose-jobs-london">
              <CaseCard
                img={imgCase1}
                meta={`Non Surgical Nose Job`}
                title="Everything you need to know about Non-Surgical Rhinoplasty
                "
              >
               Non-surgical Rhinoplasty is an aesthetic medical procedure of augmentation to alter and reshape a person's nose without carrying surgery...
              </CaseCard></Link>
            </Col>
            <Col
              lg="4"
              md="9"
              className="mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-delay="500"
            >
                <Link to="/blog/profile-balancing-shape-nose-lips-chin-affect-profile-face">
              <CaseCard
                
              
                img={imgCase3}
                meta={`Non Surgical Nose Job`}
                title="Profile balancing - How the shape of the nose, lips and chin can affect the overall profile of the face
                "
              >
                The views on facial attractiveness and youthfulness differ from one individual to another, beside the cultural difference around this topic...
              </CaseCard></ Link>
            </Col>
            <Col
              lg="4"
              md="9"
              className="mb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="true"
              data-aos-delay="500"
            >
                
                <Link to="/blog/non-surgical-rhinoplasty-fast-effective-way-improve-side-profile">
              <CaseCard
                
              
                img={imgCase2}
                meta={`Non Surgical Nose Job`}
                title="Non-surgical Rhinoplasty: A fast and effective way to improve the side profile.
                "
              >
                Have you ever wished your nose looked different? Non-surgical rhinoplasty is the answer if you’re considering a nose job and...
              </CaseCard></Link>
            </Col>
 
          </Row>
       
        </Container>
      </Box>
    </>
  );
};

export default Blo;
